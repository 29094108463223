import { App, Select, Table } from 'antd'
import { type FC, Suspense, useEffect } from 'react'
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary'

import { Loading } from './Loading'

type FallbackType = 'default' | 'table' | 'select'

const ErrorFallback: FC<FallbackProps> = ({ error }: { error: unknown }): JSX.Element => {
  const { message } = App.useApp()
  useEffect(() => {
    void message.error(`エラーが発生しました。${(error as Error).message}`)
  }, [error, message])
  return (
    <div className="text-red-500">
      <p>エラーが発生しました。{(error as Error).message}</p>
      {/* <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  )
}

const ErrorBoundaryForSuspense = ({ children, type }: { children: JSX.Element; type?: FallbackType }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  )
}

export const SuspenseWithLoading = ({ children, type }: { children: JSX.Element; type?: FallbackType }) => {
  switch (type) {
    case 'table': {
      return (
        <ErrorBoundaryForSuspense type={type}>
          <Suspense fallback={<Table loading />}>{children}</Suspense>
        </ErrorBoundaryForSuspense>
      )
    }
    case 'select': {
      return (
        <ErrorBoundaryForSuspense type={type}>
          <Suspense fallback={<Select loading />}>{children}</Suspense>
        </ErrorBoundaryForSuspense>
      )
    }
  }

  return (
    <ErrorBoundaryForSuspense type={type}>
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </ErrorBoundaryForSuspense>
  )
}
